import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Footer from "../components/footer"
import SEO from "../components/seo"

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query {      
      work: allContentfulWork(sort: { fields: updatedAt, order: DESC }), {
        nodes {
          id
          updatedAt
          title
          logoUrl
          description {
            raw
          }
        }
      }
    }
  `)
  const { work = [] } = data

  return (
    <Layout>
      <SEO title="Work | Evelyn Crowley" />
      <section className="work-header">
        <strong>Selected Clients</strong>
        <span>Work samples available on request.</span>
      </section>
      <section >
        
      </section>
      <section className="work">
        <div className="cards">

          {work.nodes.map(item => (
            <div className="card" key={item.id}>

              <div className="content">
                <div className="front">
                  <img src={item.logoUrl} alt="" />
                </div>
                <div className="back">
                  <h3>{item.title}</h3>{" "}
                  {documentToReactComponents(JSON.parse(item.description.raw))}
                </div>
              </div>

            </div>
          ))}


        </div>
      </section>
      <Footer home={false} />
    </Layout>
  )
}

export default SecondPage
